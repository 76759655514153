@import '../../../App';

.home-notes-wrapper {
  width: 50%;
  margin: 10px;

  .home-notes-wrapper-header {
    padding: 10px;
    display: flex;
    align-items: center;
    height: 30px;
    border-bottom: solid 1px $light-gray;

    span {
      margin-right: 3px;
    }
  }
  .home-notes-wrapper-content{
    width:100%;
    height:calc(100% - 50px);
    overflow: auto;
    .home-notes-wrapper-content-note:hover{
      background-color: $item-hover;
    }
    .home-notes-wrapper-content-note{
      padding:10px;
      width:calc(100% - 20px);
      border-bottom: solid 1px $light-gray;
      .home-notes-wrapper-content-note-header{
        font-size:14px;
        height:20px;
        display:flex;
        align-items: center;
        margin-bottom: 5px;

      }
      .home-notes-wrapper-content-note-content{
        display:flex;
        align-items: center;
        span:first-of-type{
          font-size:16px;
          margin-right: 3px;
        }
      }
    }

    .home-notes-wrapper-content-no-note-loader-wrapper{
      width:100%;
      height:100%;
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        width:30%;
      }
    }
  }
}