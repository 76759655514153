@import 'src/App';

.menu-wrapper {
  height: 100vh;
  width: 320px;
  position: relative;
  background-color: #1E2129;

  .menu-wrapper-header {
    background-color: $primary-color;
    height: 200px;
    position: relative;
    .menu-wrapper-header-logout{
      margin-left: 10px;
      cursor:pointer;
    }

    .menu-wrapper-header-logo{
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;

      a {
        width: 50%;

        .menu-wrapper-header-logo {
          width: 100%;
          cursor: pointer;
        }
      }

      svg {
        color: #f4f4f4;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .menu-wrapper-header-credentials {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 500;
      text-align: center;
    }

    .menu-wrapper-header-firstname-circle-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 45px;
      transform: translateY(50%);
      z-index:3;

      .menu-wrapper-header-firstname-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        background-color: $secondary-color;
        border-radius: 50%;
        overflow: visible;
        span {
          font-size: 40px;
          color: #FFFFFF
        }
      }
    }
  }

  .menu-wrapper-body {
    overflow: auto;
    background-color: $primary-color;
    height: calc(100vh - 200px);
    padding-top: 4px;
    padding-left: 4px;
    width: 320px;
    padding-bottom: 4px;

    .menu-wrapper-body-accordion-wrapper {
      width: calc(100% - 8px);
      margin: 0 4px;
      color: #FFFFFF;
      background-color: $secondary-color;

      a {
        text-decoration: none;
      }

      .menu-wrapper-body-accordion-summary {
        span {
          margin-right: 16px;
        }

        svg {
          //margin-right: 16px;
          color: #FFFFFF
        }
      }

      .menu-wrapper-body-accordion-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #FFFFFF;
        width: 100%;

        span:first-child {
          margin-right: 16px;
        }
      }

    }

    .menu-wrapper-body-button-wrapper-active {
      .menu-wrapper-body-accordion-content {
        background-color: #1079FC;
      }

      border-radius: 4px;

      svg {
        color: #FFFFFF;
      }

      span {
        color: #FFFFFF;
      }
    }

  }
}
