.exports-wrapper{
  padding:10px;
  display:flex;
  .exports-wrapper-export{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:10px;
  }
}