@import '../../../App';

.stats-date-wrapper {
  margin: 10px;
  display: flex;
  align-items: center;
}

.stats-wrapper {
  width: 100%;

  .stats-wrapper-title {
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px $light-gray;
    font-size: 24px;

    div{
      font-size: 18px;
    }
  }

  .stats-wrapper-graph-table {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .stats-wrapper-graph {
      width: 500px;
    }

    .stats-wrapper-table {
      width: 700px;
      th{
        font-weight: bold;
      }
    }
  }

}
