@import '../../../App';

.home-kpi-card-wrapper{
  width:25%;
  display: flex;
  flex-direction: column;
  margin:10px;
  padding:10px;
  .home-kpi-card-wrapper-label{
    display:flex;
    font-size:16px;
    margin-bottom: 3px;
    align-items: center;
    span{
      font-size:16px;
      margin-right: 3px;
    }
  }
  .home-kpi-card-wrapper-count{
    display:flex;
    font-size:30px;
    font-weight:bold;
    margin-bottom: 3px;
  }
  .home-kpi-card-wrapper-subtitle{
    display:flex;
    font-size:16px;
  }
}