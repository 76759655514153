@import '../../../App';

.home-turnover-wrapper {
  width: 50%;
  margin: 10px;

  .home-turnover-wrapper-header {
    padding: 10px;
    display: flex;
    align-items: center;
    height: 30px;
    border-bottom: solid 1px $light-gray;

    span {
      margin-right: 3px;
    }
  }

  .home-turnover-wrapper-content {
    width: 100%;
    height: calc(100% - 50px);
    overflow: auto;
    canvas{
      padding:10px;
      max-height:100%;
    }
  }

  .home-turnover-wrapper-content-no-turnover-loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
      width:30%;
    }
  }
}