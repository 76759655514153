#operation-details-popper {
  z-index: 5;
}

.operation-details-modal-wrapper {
  margin-left: 12px;
  padding: 10px;

  .operation-details-modal-wrapper-header {
    font-weight: bold;
    margin-bottom: 10px;
    div {
      margin-top: 4px;
      height: 1px;
      width: 100%;
      background-color: #1E2129;
    }
  }
  .operation-details-modal-wrapper-content {
    th{
      font-weight: bold;
    }
    .operation-details-modal-wrapper-content-libelle-cell{
      display:flex;
      justify-content: space-between;
    }
  }
}

.operation-details-modal-wrapper-loader {
  display: flex;
  justify-content: center;
}