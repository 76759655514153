@import '../../../App';

.super-search-wrapper{
  margin-right: 16px;
  color:#FFFFFF;
  display:flex;
  align-items: center;
  span{
    font-size:32px;
    margin-right: 16px;
  }
  .super-search-textfield{
    width:350px;
    label, div, input, fieldset{
      color:#FFFFFF !important;
      border-color: #FFFFFF !important;
    }
  }
}
