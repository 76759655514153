@import 'src/App';

.operations-production-wrapper {
  width: 100%;
  height: 100%;

  .operations-production-wrapper-header {
    padding: 10px;
    height:110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:24px;
    .operations-production-wrapper-header-counts {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      span:first-of-type {
        margin-bottom: 5px;
      }
    }

    .operations-production-wrapper-header-filter-sort {
      .operations-production-wrapper-header-sort-input {
        width: 300px;
      }

      .operations-production-wrapper-header-filter-input {
        margin-left: 10px;
        width: 300px;
      }

      button {
        border: solid 1px rgb(196, 196, 196);
        margin-left: 10px;
      }
    }
  }

  .operations-production-wrapper-content {
    padding: 10px;
    padding-top: 0;
    height:calc(100% - 110px);
    .operations-production-wrapper-content-table {
      height: calc(100% - 46px);
      overflow-y: auto;
      .operations-production-wrapper-content-table-actions-buttons{
        display:flex;
        button{
          white-space: nowrap;

        }
        button:last-of-type{
          margin-left: 5px;
        }
      }

      .operations-production-wrapper-content-table-operation-ref-wrapper {
        cursor:pointer;
        background-color: #1079FC;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        padding: 4px;
        margin-top: -2px;
        margin-bottom: -2px;
        font-weight: bold;
        border-radius: 4px;
        color: #FFFFFF;
        width: fit-content;
      }

      th {
        font-weight: bold;
      }

      //tr:hover {
      //  background-color: rgba(0, 0, 0, 0.12);
      //}
    }

    .operations-production-wrapper-content-pagination {
      display:flex;
      justify-content: flex-end;
      margin-top:10px;
    }
  }
  .operations-production-wrapper-content-loader-wrapper{
    padding:10px;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%);
  }
}
