@import 'src/App';

.providers-list-wrapper {
  width: 100%;
  height: 100%;

  .providers-list-wrapper-header {
    padding: 10px;
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;

    .providers-list-wrapper-header-counts {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      span:first-of-type {
        margin-bottom: 5px;
      }
    }

    .providers-list-wrapper-header-filter {
      .providers-list-wrapper-header-filter-input {
        width: 300px;
      }

      .operations-production-wrapper-header-filter-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }

  .providers-list-wrapper-content {
    padding: 10px;
    padding-top: 0;
    height: calc(100% - 110px);

    .providers-list-wrapper-content-table {
      height: calc(100% - 46px);
      overflow-y: auto;

      th {
        font-weight: bold;
      }

      tr {
        cursor: pointer;
      }

      tr:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }

    .providers-list-wrapper-wrapper-content-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  .providers-list-wrapper-content-loader-wrapper {
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
  }
}

.provider-wrapper-content {
  padding: 10px;
  width: 100%;
  overflow: auto;
  height: 100%;

  .provider-wrapper-details-contacts {
    display: flex;
    justify-content: space-between;
    height: 230px;

    .providers-provider-details-wrapper {
      padding: 10px;
      width: calc(50% - 5px);

      .providers-provider-details-wrapper-title {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        font-size: 18px;
        align-self: flex-start;
      }

      .providers-provider-details-wrapper-content {
        line-height: 20px;
      }

      .provider-details-left-line-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 2px;
      }

      .provider-details-left-line-content {
        width: 350px;
        padding-right: 12px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .provider-contacts-wrapper {
      padding: 10px;
      width: calc(50% - 5px);

      .provider-contacts-wrapper-title {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        font-size: 18px;
        align-self: flex-start;
      }
    }

    .provider-contacts-wrapper-table {
      table {
        thead {
          tr {
            th {
              font-weight: bold !important;
            }
          }
        }
      }
    }
  }


  .provider-contacts-content-no-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #CCCCCC;

    img {
      width: 30%;
      margin-bottom: 20px;
    }
  }
}


.provider-orders-wrapper {
  width: 100%;
  margin-top: 10px;
  height: calc(100% - 240px);
  position: relative;

  .provider-orders-wrapper-content-table-add-button {
    position: absolute;
    top: 14px;
    right: 26px;
    z-index: 10;
    background-color: #1079FC;
  }

  .provider-orders-wrapper-content-table {
    height: calc(100% - 46px);

    .provider-orders-wrapper-content-table-actions-buttons {
      display: flex;

      button {
        white-space: nowrap;
      }
    }

    .provider-orders-wrapper-content-table-operation-ref-wrapper {
      background-color: #1079FC;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      padding: 4px;
      margin-top: -2px;
      margin-bottom: -2px;
      font-weight: bold;
      border-radius: 4px;
      color: #FFFFFF;
      width: fit-content;
    }

    th {
      font-weight: bold;
    }

    tr:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .provider-orders-wrapper-content-pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
  }
}


