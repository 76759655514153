@import 'src/App';

.operations-details-wrapper{
  width: 100%;
  height: calc(100vh - 220px);
  display:flex;
  .operations-details-left-part{
    width:80%;
    .operations-details-wrapper-header{
      height:40px;
      display:flex;
      justify-content: space-between;
      padding:10px;
      font-size: 24px;
      align-self: flex-start;
    }
    .operations-details-table-wrapper{
      padding: 10px;
      height:calc(100% - 80px);
      .operations-details-table-wrapper-title{
        padding:10px;
        margin-bottom: 10px;
      }
      .operations-details-table-wrapper-table {
        width:100%;
        max-height:100%;
        .operations-details-table-wrapper-table-chip{
          margin-right: 5px;
        }
        .operations-production-wrapper-content-table-actions-buttons{
          display:flex;
          button{
            white-space: nowrap;

          }
          button:last-of-type{
            margin-left: 5px;
          }
        }

        .operations-production-wrapper-content-table-operation-ref-wrapper {
          cursor:pointer;
          background-color: $primary-color;
          padding: 4px;
          margin-top: -2px;
          margin-bottom: -2px;
          font-weight: bold;
          border-radius: 4px;
          color: #FFFFFF;
          width: fit-content;
        }

        th {
          font-weight: bold;
        }
      }
      .operations-details-table-wrapper-footer{
        margin-top: 20px;
        display:flex;
        justify-content: space-between;
        .operations-details-table-wrapper-footer-total{
          padding-right: 10px;
          line-height:20px;
          font-weight: bold;
        }

      }
    }
  }

  .operations-details-right-part{
    width:20%;
    height:100%;
    margin: 10px;
    padding:10px;
    overflow: auto;
    .operations-details-right-part-title{
      font-size:20px;
      margin-bottom: 30px;
    }
    .operations-details-right-part-category-wrapper{
      .operations-details-right-part-category-wrapper-title{
        margin-bottom: 5px;

      }
      .operations-details-right-part-category-wrapper-button{
        display:flex;
        padding: 10px;
        justify-content:flex-start;
        align-items: center;
        height:40px;
        cursor:pointer;
        &.disabled {
          color: #797979 !important;
        }
      }
      .operations-details-right-part-category-wrapper-button:hover{
       background: #E9E9E9;
        &.disabled {
          background: inherit;
          cursor: default;
        }
      }
    }
  }

  .operations-production-wrapper-content-loader-wrapper{
    padding:10px;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%);
  }


}

.table-row-to-reorder {

}

.table-row-desired {
  background-color: rgba(16, 42, 208, 0.3);
}

.draggable-row {
  cursor: grab  ;
}

.price-tag-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .label {

  }

  .spacer {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    height: 1px;
    background: #797979;
  }

}
.price-resume {
  background: white;
  border: 1px solid darkgray;
  border-radius: 3px;
  padding: 10px;
}

.add-elements-container {
  display:flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
}
