@import '../../../App';

.home-wrapper{
  width:100%;
  .home-wrapper-kpis{
    display:flex;
    width:100%;
    justify-content: space-between;
    margin-bottom: 20px;
    .home-wrapper-kpis-kpi{
      width:22%;
    }
  }
  .home-wrapper-notes-turnover {
    height: 400px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .home-wrapper-charts {
    height: calc(100% - 557px);
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}