@import 'src/App';

.login-wrapper {
  width: 100%;
  height: 100vh;
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-container {
    padding-top: 150px;
    display: flex;

    img {
      width: 300px;
    }
  }

  .login-text-fields-wrapper {
    margin-top: -30px;
    border-radius: 14px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h2 {
      color: #FFFFFF;
      margin-bottom: 12px;
    }

    span {
      font-size: 14px;
      color: #FFFFFF;
      margin-bottom: 24px;
    }

    .login-password-container {
      width: 100%;
      position: relative;
      .login-password-eye {
        position: absolute;
        right: 0;
        top: 0;
        height: 52px;
        width: 52px;
        z-index: 5;
        button {
          height: 52px;
          width: 52px;
          border-radius: 30px;
          svg {
            color:#797979;
            font-size:16px;
          }
        }
      }
    }

    .login-text-fields {
      width: 100%;
      margin-bottom: 12px !important;
      margin-top: 0;
    }

    fieldset {
      border-radius: 30px;
    }
  }
}