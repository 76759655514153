@import 'src/App';

.provider-all-orders-wrapper {
  height:100%;
  width: 100%;
  padding:10px;

  .provider-all-orders-wrapper-header {
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;

    .provider-all-orders-wrapper-header-filter {
      display:flex;
      align-items: center;
      .provider-all-orders-wrapper-header-filter-input {
        width: 300px;
      }

      .operations-production-wrapper-header-filter-input {
        margin-left: 10px;
        width: 300px;
      }
    }
  }

  .provider-all-orders-wrapper-content-table {
    height:calc(100% - 156px);

    .provider-orders-all-wrapper-content-table-actions-buttons {
      display: flex;

      button {
        white-space: nowrap;
      }
    }

    .provider-all-orders-wrapper-content-table-operation-ref-wrapper {
      background-color: #1079FC;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      padding: 4px;
      margin-top: -2px;
      margin-bottom: -2px;
      font-weight: bold;
      border-radius: 4px;
      color: #FFFFFF;
      width: fit-content;
    }

    th {
      font-weight: bold;
    }

    tr:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .provider-all-orders-wrapper-content-pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
  }
}
