$primary-color: #1E2129;
$secondary-color: #313540;
$hover-item: #E9E9E9;
$light-gray:#D3D3D3;
$item-hover:rgba(0, 0, 0, 0.12);

/* Hide scrollbar for Chrome, Safari and Opera */
.noscrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noscrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.router-content-wrapper{
  height:calc(100vh - 60px);
  width: 100%;
  background-color: #1E2129;
  padding: 8px;
  padding-top: 0;
  padding-left: 4px;
  position: absolute;
  left: 0;
  right: 0;
  top:60px;
  bottom:0;
}

.main-card-content{
  background-color: #FAFAFA;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
}

* {
  box-sizing: border-box;
}

.link-without-decoration{
  text-decoration:none;
  color: $primary-color !important;
}
