@import 'src/App';

.operations-bill-wrapper {
  width: 100%;
  height: 100%;

  .operations-bill-wrapper-header {
    padding: 10px;
    height:110px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    .operations-bill-wrapper-header-counts {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      span:first-of-type {
        margin-bottom: 5px;
      }
    }

    .operations-bill-wrapper-header-filter-sort {
      .operations-bill-wrapper-header-sort-input {
        width: 300px;
      }

      .operations-bill-wrapper-header-filter-input {
        margin-left: 10px;
        width: 300px;
      }

      button {
        border: solid 1px rgb(196, 196, 196);
        margin-left: 10px;
      }
    }
  }

  .operations-bill-wrapper-content {
    padding: 10px;
    height:calc(100% - 150px);
    .operations-bill-wrapper-content-table {
      height: calc(100% - 46px);
      overflow-y: auto;
      .operations-bill-wrapper-content-table-actions-buttons{
        display:flex;
        button{
          white-space: nowrap;
          margin-left: 5px;

        }
        button:first-of-type{
          margin-left:0
        }
      }

      .operations-bill-wrapper-content-table-operation-ref-wrapper {
        cursor:pointer;
        background-color: $primary-color;
        padding: 4px;
        margin-top: -2px;
        margin-bottom: -2px;
        font-weight: bold;
        border-radius: 4px;
        color: #FFFFFF;
        width: fit-content;
      }

      th {
        font-weight: bold;
      }

      //tr:hover {
      //  background-color: rgba(0, 0, 0, 0.12);
      //}
      .operations-bill-wrapper-content-table-operations-ids{
        display:flex;
      }
    }

    .operations-bill-wrapper-content-pagination {
      display:flex;
      justify-content: flex-end;
      margin-top:10px;
    }
  }
  .operations-bill-wrapper-content-loader-wrapper{
    padding:10px;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%);
  }
}