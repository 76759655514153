@import '../../../App';

.header-wrapper{
  width:100%;
  color:#FFFFFF;
  height:60px;
  background-color:$primary-color;
  display:flex;
  align-items: center;
  justify-content: space-between;
  .header-wrapper-title{
    display: flex;
    align-items: center;
    margin-left: 10px;
    button{
      margin-right: 16px;
    }
    button:hover{
      background-color: rgba(25, 118, 210, 0.04);
    }

  }
}
