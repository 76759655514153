@import 'src/App';

.new-operation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .new-operation-wrapper {
    padding: 10px;

    .new-operation-header {
      font-size: 20px;
      align-self: flex-start;
      margin-bottom: 18px;

      .new-operation-header-line {
        margin-top:4px;
        height: 1px;
        width: 100%;
        background-color: $primary-color;
      }

    }

    .new-operation-fields-wrapper {
      width:800px;
      display: flex;
      flex-direction: column;

      div:last-of-type {
        margin-bottom: 0;
      }

      div {
        margin-bottom: 12px;
      }

      label:last-of-type {
        margin-bottom: 12px;
      }

      .new-operation-fields-wrapper-field {
        width: 100%;
      }

      .new-operation-field-wrapper-autocomplete-client-fields-wrapper {
        padding: 4px;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }

      .new-operation-field-wrapper-autocomplete-client-fields-wrapper:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
    .new-operation-buttons{
      margin-top: 20px;
      display:flex;
    }
  }
}