@import 'src/App';

.products-unique-all-products-wrapper {
  width: 100%;
  height:100%;

  .products-unique-all-products-wrapper-header {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .products-unique-all-products-wrapper-header-title {
      font-size: 24px;
    }

    .products-unique-all-products-wrapper-header-counts {
      display: flex;
      span:first-of-type {
        margin-bottom: 5px;
      }
    }

    .products-unique-all-products-wrapper-header {
      .products-unique-all-products-wrapper-header-sort-input {
        width: 300px;
      }

      .products-unique-all-products-wrapper-header-filter-input {
        margin-left: 10px;
        width: 300px;
      }

      button {
        border: solid 1px rgb(196, 196, 196);
        margin-left: 10px;
      }
    }
  }

  .products-unique-all-products-wrapper-content {
    padding: 10px;
    height:calc(100% - 62px);
    .products-unique-all-products-wrapper-content-table {
      overflow-y: auto;
      min-height: 477px;
      height:calc(100% - 42px);
      .products-unique-all-products-wrapper-content-table-finish-buttons {
        display: flex;
        justify-content: space-between;
      }

      .products-unique-all-products-wrapper-content-table-actions-buttons {
        display: flex;

        button {
          white-space: nowrap;

        }

        button:last-of-type {
          margin-left: 5px;
        }
      }

      .products-unique-all-products-wrapper-content-table-operation-ref-wrapper {
        cursor: pointer;
        background-color: $primary-color;
        padding: 4px;
        margin-top: -2px;
        margin-bottom: -2px;
        font-weight: bold;
        border-radius: 4px;
        color: #FFFFFF;
        width: fit-content;
      }

      th {
        font-weight: bold;
      }

      //tr:hover {
      //  background-color: rgba(0, 0, 0, 0.12);
      //}
    }

    .products-unique-all-products-wrapper-content-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  .products-unique-all-products-wrapper-content-loader-wrapper {
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
  }
}

.products-edit-unique-article-text-fields-wrapper{
  width:800px;
  margin-top:10px;
  display:flex;
  flex-direction: column;
  .ecriture-wrapper{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
}

.products-create-serie-modal-text-fields-wrapper{
  margin-bottom: 12px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  .products-create-serie-modal-text-fields-wrapper-text-field{
    width:20%;
  }
}


