@import '../../../App';

.super-search-modal-wrapper{
  .super-search-modal-wrapper-header{
    display:flex;
    align-items: center;
    border-bottom: solid 1px $light-gray;
    span{
      margin-right: 16px;
    }
  }
  .super-search-modal-wrapper-content{
    position:relative;
    overflow: hidden;
    padding:10px !important;
    .super-search-modal-wrapper-content-textfield{
      width:100%;
      height:40px;
      margin-bottom: 10px;
    }
    .super-search-modal-wrapper-content-noresult{
      margin-top: 100px;
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        width:40%;
      }
    }
    .super-search-modal-wrapper-content-buttons{
      height:50px;
      margin-bottom: 10px;
      .super-search-modal-wrapper-content-buttons-button-wrapper{
       display:flex;
        .super-search-modal-wrapper-content-buttons-button-wrapper-count{
          margin-left:6px;
          display:flex;
          justify-content: center;
          align-items: center;
          color:#FFFFFF;
          min-width:20px;
          padding:0 4px;
          background-color: $primary-color;
          border-radius: 8px;
        }
      }
    }
    .super-search-modal-wrapper-content-table{
      overflow-y: auto;
      .super-search-modal-wrapper-content-table-operation-ref-wrapper{
        background-color: $primary-color;
        padding:4px;
        margin-top:-2px;
        margin-bottom:-2px;
        font-weight:bold;
        border-radius: 4px;
        color:#FFFFFF;
        width:fit-content;
      }
    }
    th{
      font-weight:bold;
    }
    tr:hover{
      background-color:rgba(0, 0, 0, 0.12);
    }
  }
  .super-search-modal-wrapper-content-loader-wrapper{
    padding:10px;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%);
  }
  .super-search-modal-wrapper-content-pagination{
    height:30px;
    display:flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .super-search-modal-wrapper-footer{
    border-top: solid 1px $light-gray;
  }

}